import React, { useState, useEffect } from "react";
import "./Admin.css";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Dashboard from "../Dashboard/Dashboard";

const AdminQueries = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "3"
    ) {
      alert(
        "You are not authorized to access this page. Login with super admin credentials"
      );
      navigate("/login");
    }
  });
  return (
    <>
      {/* <Header/> */}
      <Dashboard />

      <section className="dashboard">
        <div className="top1">
          <i className="uil uil-bars sidebar-toggle"></i>

          <div className="search-box1">
            <i className="uil uil-search"></i>
            <input type="text" placeholder="Search here..." />
          </div>
        </div>

        <div className="dash-content">
          <div className="activity">
            <div className="title">
              <i className="uil uil-clock-three"></i>
              <span className="text">Recent Activity</span>
            </div>

            <div className="activity-data">
              <div className="data names">
                <span className="data-title">How many credits?</span>
                <span className="data-list">What are types of specs?</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminQueries;
