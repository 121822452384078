import React, { useState, useEffect } from "react";
import StudentDashboard from "../Dashboard/StudentDashboard";
import "./Club.css";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import axios from "axios";

function Orders() {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const [clubdetails, setclubdetails] = useState([]);
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));

  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "1"
    ) {
      alert(
        "You are not authorized to access this page. Login with student credentials"
      );
      navigate("/login");
    }
  });

  useEffect(() => {
    if (flag) {
      axios
        .get("http://nxk9083.uta.cloud/api/public/orders.php?id=" + userDetails1["id"])
        .then((result) => {
          const data = result.data;
          if (data["error"]) {
            data = [];
          }
          setclubdetails(data);
          console.log(data);
        });
    }
    setFlag(false);
  });

  return (
    <>
      {/* <Header/> */}
      <StudentDashboard />
      <div className="card-lis">
        <br />
        <br />
        <div>
          <center>
            <h1>My Orders</h1>
          </center>
        </div>
        <br />
        <br />

        <ul className="card-wrapper">
          {clubdetails.map((product) => {
            return (
              <li className="card">
                <img
                  src="https://www.nicepng.com/png/detail/299-2992860_completed-order-comments-order-completed-icon.png"
                  alt=""
                />
                <p>Order Id: {product["id"]}</p>
                <p>Order Total:{product["total"]}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default Orders;
