import React, { useRef } from "react";
import "./Register.css";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

function Register() {
  const name = useRef(null);
  const email = useRef(null);
  const password = useRef(null);
  const phone = useRef(null);
  const address = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const otp = Math.floor(100000 + Math.random() * 900000).toString();

    const userdata = {};
    userdata["name"] = name.current.value;
    userdata["email"] = email.current.value;
    userdata["password"] = password.current.value;
    userdata["phone"] = phone.current.value;
    userdata["address"] = address.current.value;
    userdata["email_code"] = otp;

    var re =
      /^(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z])(?=\D*\d)(?=[^!#@%]*[!@$&#%])[A-Za-z0-9!@$&#%]{8,32}$/;
    if (re.test(password.current.value) == false) {
      alert("Password not strong");
      return;
    }

    axios
      .post("http://nxk9083.uta.cloud/api/public/reg.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status_code == 1) {
          axios.post("http://nxk9083.uta.cloud/api/public/send_email.php", userdata);
          navigate("/login");
        }
      });

    event.target.reset();
  };

  return (
    <>
      <div className="reg">
        <div className="container" id="container">
          <div className="form-container sign-up-container">
            <form action="#">
              <h1>Create Account</h1>
              {/* <div className="social-container">
            <a href="#" className="social"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
          </div>
          <span>or use your email for registration</span> */}
              <input ref={name} type="text" placeholder="Name" />
              <input ref={email} type="email" placeholder="Email" />
              <input ref={password} type="password" placeholder="Password" />
              <input ref={phone} type="number" placeholder="Phone" />
              <button>Sign Up</button>
            </form>
          </div>
          <div className="form-container sign-in-container">
            <form onSubmit={handleSubmit}>
              <h1>Create Account</h1>
              {/* <div className="social-container">
            <a href="#" className="social"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
            <a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
          </div>
          <span>or use your account</span> */}
              <input ref={name} type="text" placeholder="Name" />
              <input ref={email} type="email" placeholder="Email" />
              <input ref={password} type="password" placeholder="Password" />
              <input ref={phone} type="number" placeholder="Phone" />
              <input ref={address} type="text" placeholder="Address" />
              <button>Register</button>
            </form>
          </div>
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-right">
                <h1>Welcome Back!</h1>
                <p>
                  To keep connected with us please login with your personal info
                </p>
                <NavLink to={"/login"}>
                  <button className="ghost" id="signIn">
                    Sign In
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
