import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import StudentDashboard from "../Dashboard/StudentDashboard";
import Header from "../Header/Header";
import "./Profile.css";
import axios from "axios";

function Profile() {
  const [flag, setFlag] = useState(true);
  const [userDetails, setUserDetails] = useState(true);
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));
  const navigate = useNavigate();

  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "1"
    ) {
      alert(
        "You are not authorized to access this page. Login with student credentials"
      );
      navigate("/login");
    }
  });

  useEffect(() => {
    if (flag) {
      axios
        .get("http://nxk9083.uta.cloud/api/public/profile.php?id=" + userDetails1["id"])
        .then((result) => {
          const data = result.data;
          setUserDetails(data);
        });
    }
    setFlag(false);
  });

  return (
    <>
      <StudentDashboard />

      <div className="wrapper">
        <div className="left">
          <img src={userDetails["image"]} alt="user" width="100" />
          <h4>{userDetails["full_name"]}</h4>
          <p>UI Developer</p>
        </div>
        <div className="right">
          <div className="info">
            <h3>Information</h3>
            <div className="info_data">
              <div className="data">
                <h4>Email</h4>
                <p>{userDetails["email_id"]}</p>
              </div>
              <div className="data">
                <h4>Phone</h4>
                <p>{userDetails["phone_num"]}</p>
              </div>
            </div>
          </div>

          <div className="projects">
            <h3>Projects</h3>
            <div className="projects_data">
              <div className="data">
                <h4>Recent</h4>
                <p>Lorem ipsum dolor sit amet.</p>
              </div>
              <div className="data">
                <h4>Most Viewed</h4>
                <p>dolor sit amet.</p>
              </div>
            </div>
          </div>
          <NavLink to={"/student/editprofile/" + userDetails["id"]}>
            <button style={{ backgroundColor: "white", color: "black" }}>
              <span className="data-title">Edit Profile</span>
            </button>
          </NavLink>
        </div>
      </div>
    </>
  );
}

export default Profile;
