import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { NavLink, useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "3"
    ) {
      alert(
        "You are not authorized to access this page. Login with super admin credentials"
      );
      navigate("/login");
    }
  });

  return (
    <>
      <div className="defdashb">
        <nav>
          <div className="logo-name">
            <span className="logo_name">Mercado Escolar</span>
          </div>
          <h6 className="panelHeading">Super Admin Panel</h6>

          <div className="menu-items">
            <ul className="nav-links">
              <li>
                <NavLink to={"/superadmin/queries"}>
                  <a href="#">
                    <i className="uil uil-estate"></i>
                    <span className="link-name">Queries</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/superadmin/users"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Users</span>
                  </a>
                </NavLink>
              </li>
              {/* <li>
        <NavLink to={"/superadmin/schools"}>
          <a href="#">
            <i className="uil uil-chart"></i>
            <span className="link-name">Schools</span>
          </a>
        </NavLink>
        </li> */}
              <li>
                <NavLink to={"/superadmin/insights"}>
                  <a href="#">
                    <i className="uil uil-thumbs-up"></i>
                    <span className="link-name">Insights</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Logout</span>
                  </a>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Dashboard;
