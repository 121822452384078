import React, { useState, useEffect } from "react";
import "./Chat.css";
import io from 'socket.io-client';
import BusinessDashboard from "../Dashboard/BusinessDashboard";
import axios from "axios";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  AvatarGroup,
  Button,
  Conversation,
  ConversationHeader,
  StarButton,
  VoiceCallButton,
  VideoCallButton,
  InfoButton,
  ConversationList,
  InputToolbox,
  Loader,
  TypingIndicator,
  StatusList,
  Status,
  Sidebar,
  Search,
  MessageSeparator,
  action,
  ExpansionPanel
} from "@chatscope/chat-ui-kit-react";



const BusinessChat = () => {

  const socket = io('http://localhost:3001', {
    rejectUnauthorized: false,
    secure: false
  });

  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [users, setUsers] = useState([]);
  const [messageInputValue, setMessageInputValue] = useState("");
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [currentChat, setCurrentChat] = useState(0);
  const [myInfo, setMyInfo] = useState(JSON.parse(localStorage.getItem('userdetails')));
  useEffect(() => {
    getUsers();
  });
  

  socket.on('chat message', (message) => {
    console.log(message);
    setMessages([...messages, message]);
  });

  const sendMessage = () => {
    // e.preventDefault();
    let message = {
      message: messageInputValue,
      sender_id: myInfo.id,
      receiver_id: currentChat
    }
    socket.emit('chat message', message);
    axios.get('http://nxk9083.uta.cloud/api/public/chats.php?message=' + messageInputValue + '&sender_id='+ myInfo.id +'&receiver_id='+ currentChat +'&type=add').then((result) => {
      console.log(JSON.stringify(result.data));
    });
    console.log( message);
    setMessageInputValue("");
  };

  const getMessages = (id) => {
    setMessages([]);
    // console.log(id, users);
    // setTimeout(() => {
    //   console.log('u', users);

      axios.get(`http://nxk9083.uta.cloud/api/public/chats.php?other_id=${id}&my_id=${myInfo.id}&type=single`).then((result) => {
        console.log(result.data.user_data);
        setMessages(result.data.user_data);
      });
    // }, 1000);
  };

  const getUsers = () => {
    if (users.length === 0) {
    fetch('http://nxk9083.uta.cloud/api/public/chats.php?type=multiple&my_id=1',
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
    }}).then((response) => response.json())
    .then((responseJson) => {
      console.log("users", responseJson.user_data);
      
      setUsers(responseJson.user_data);
      setCurrentChat(responseJson.user_data[0].id);
      
      if (messages.length === 0) {
        getMessages(responseJson.user_data[0].id);
      }

      // add unique users to state
      setUniqueUsers(uniqueUsers);
    });
  }
  };

  const avatarIco =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAZlBMVEUOHCyclYufmI0AECZvbGkAACCjm5AIGCoxOUIAEycAFSgLGisNHCwEFykDFyljY2N9enUlLjkACCKWkIc+Q0lmZmWIhH0bJjN/e3YVIjGSjYRAREpbXF0tND54dXGEgHpKTVFTVVcfARIMAAADVklEQVR4nO3ciXaiMABA0ZA4lhBEcV+r/v9PTtA6FUVGLXOyzLtf4DtktVghAAAAAAAAAAAAAAAAAAAAAABAuIwej9XAuP4Y/4xR5XY+6U11pI1GL4ZrmSQyGaXZIHf9cTqXa7Gt+ipSfqZ64PoTdcuoYjj56js3jtJxRM/RqMUwueo7Ny6nqohjPtr1Zbi+6Ts1JqNpFsGak2eLxr5z4zItAp+PRtfn313jaT66/pTvM2p1N//uGvv7YOdjNf/ant/VWJ3qABsv+/szzmtOWHtHrldP950a7XwM6QxglJk9Mz7rjcvpOJCxWs2/v60vzY37qc78b7R9s1fGZ60xWW58PwMYu7+/Oj5vGr0+A9yer99qrM4AheuSZnZ/n8kf9p0a7RnAyzVHly+vnw8bq/no3faYbd5dX5obe749xNy8s0G0NW6166a6bNttYJJMxq6b6lSv68L+L9dNdRRSSKF7FFJIoXsUUkihexRSSKF7FFJIoXsUUkihexRSSKF7FFJIoXsUUkihexRSSKF7FL5Oxl4oR8p1U13XhXJdevb6ZbeFUo5K396E7rJQyvlBfLguutVdoUyWB+PfO9BdFUopZztV+NfXUaHs749KebbCXHTwFrScfKbGs5e7r5iy/7M8uR7ulNe/0Bt//uTHQNXq6evwvMjz+buJMumlYw9Xz1sfi7cS7ePbikB+XJntXk+Uk9FmpT0fnt+K3frFxzeZpdrLze+RbPdKX39+XKmPkPqsLJ0825d82tUlmOH5LZs+k2gf37DMwlhd7mSbJx7f/mBXl8CG5x+5PvzlcCP3UxXi8Pymju17xjys1bOJaj2Ey6O/h+tnGT1s+38taaArzLU8m7Ukpt59P/GGvO0+HEWhMC13qTgKRV48TIykUBgxepAYS6Ew+b45MZpCu2k0XxfjKRRm1ZgYUaEoyqbEmArtjbjhv4FEVdh46Y+rsCkxskKhN7eX/tgKhTrEXmgTZeSFuap/rxFf4e33GjEW1i/9MRbWL/1RFopc9/pxF15/rxFpoR2ol0t/rIX2Rvx16Y+20F4Xz5f+eAvtUzxdFyMuFKaw10Xp2zuHnRqU8/5chf53mVaDxSHqRyiqgRp5IAAAAAAAAAAAAAAAAAAAAAAA/4Hf0gU2cK/EibwAAAAASUVORK5CYII=";

  
  return (
    <>
      <BusinessDashboard />
      <div>
      <MainContainer responsive>
        <Sidebar position="left" scrollable={false}>
          <ConversationList>
            {
              users.map((user) => {
                return (
                  <Conversation
                    key={user.id}
                    name={user.full_name}
                    active={currentChat === user.id}
                    onClick={() => {
                      setCurrentChat(user.id);
                      
                      getMessages(user.id);
                    }}
                  >
                  <Avatar src={avatarIco} name={user.full_name} status="available" />
                    </Conversation>
                );
              })
            }
                
          </ConversationList>
        </Sidebar>

        <ChatContainer>
          <ConversationHeader>
            <ConversationHeader.Back />
            <Avatar src={avatarIco} name="Zoe" />
            <ConversationHeader.Content
              userName={

                users.indexOf(users.find(user => user.id === currentChat)) !== -1 ? users[users.indexOf(users.find(user => user.id === currentChat))].full_name : "No User"
              }
              // info={}
            />
          </ConversationHeader>
          <MessageList>
            {/* <MessageSeparator content="Saturday, 30 November 2019" /> */}
            {
              messages?.map((message) => {
                console.log(typeof parseInt(myInfo.id), parseInt(myInfo.id) === message.sender_id)
                return (
                  <Message
                  model={{
                    message: message.message,
                    sentTime: "15 mins ago",
                    sender: "Patrik",
                    direction: parseInt(myInfo.id) !== message.sender_id ? "outgoing" : "incoming",
                    position: "single"
                  }}
                  avatarSpacer
                />
                );
              })
            }
            
          </MessageList>
          <MessageInput
            placeholder="Type message here"
            value={messageInputValue}
            onChange={(val) => {
              setMessageInputValue(val)
            }}
            onSend={sendMessage}
          />
        </ChatContainer>

      </MainContainer>
      </div>
    </>
  );
};

export default BusinessChat;
