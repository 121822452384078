import "./EditForm.css";
import { useLocation } from "react-router-dom";
import React, { useRef, useEffect } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

function Payment() {
  let location = useLocation();
  location = location.pathname;
  const type = location.indexOf("add") > -1 ? "Add" : "Update";
  const navigate = useNavigate();
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));
  const params = useParams();

  const name = useRef(null);
  const qty = useRef(null);
  const total = params.total;

  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "1"
    ) {
      alert(
        "You are not authorized to access this page. Login with student credentials"
      );
      navigate("/login");
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();

    var re = /^[0-9]{16}$/;
    if (re.test(name.current.value) == false) {
      let info = "Enter card number in valid format, 16 numbers";
      alert(info);
      return;
    }

    var re = /^[0-9]{3}$/;
    if (re.test(qty.current.value) == false) {
      let info = "Enter card number in valid format, 3 numbers";
      alert(info);
      return;
    }

    const userdata = {};
    userdata["total"] = total;
    userdata["id"] = userDetails1["id"];

    axios
      .post("http://nxk9083.uta.cloud/api/public/orders.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status == 1) {
          alert("Order created successfully");
          navigate("/student/content");
        }
      });

    event.target.reset();
  };

  return (
    <div>
      <form className="form-style-9" onSubmit={handleSubmit}>
        <h1>Product</h1>
        <ul>
          <li>
            <input
              type="text"
              name="field1"
              className="field-style field-split align-left"
              placeholder="Full Name"
              required
            />
          </li>
          <li>
            <input
              ref={name}
              type="number"
              name="field1"
              className="field-style field-split align-left"
              placeholder="Card number"
              required
            />
          </li>
          <li>
            <input
              type="date"
              name="field2"
              className="field-style field-split align-right"
              placeholder="Price"
              required
            />
          </li>

          <li>
            <input
              type="number"
              ref={qty}
              name="field2"
              className="field-style field-split align-right"
              placeholder="CVV"
            />
          </li>
          <li>
            <input
              type="number"
              value={total}
              name="field2"
              className="field-style field-split align-right"
              placeholder="total"
              disabled
            />
          </li>

          <li>
            <input type="submit" />
          </li>
        </ul>
      </form>
    </div>
  );
}

export default Payment;
