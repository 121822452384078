import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const AddToCart = () => {
  const [flag, setFlag] = useState(true);
  const { a, b } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (flag) {
      axios
        .get("http://nxk9083.uta.cloud/api/public/addtocart.php?pid=" + a + "&uid=" + b)
        .then(function (response) {
          console.log(response.data);
          console.log(response.data.status);
          if (response.data.status_code == 1) {
            navigate("/student/products/");
          }
        });
    }
    setFlag(false);
  });

  return <div></div>;
};
export default AddToCart;
