import React, { useRef } from "react";
import "./EditForm.css";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

function AddForm() {
  const { a } = useParams();
  const type = "User";
  let location = useLocation();
  console.log(location);
  location = location.pathname;
  let op = location.includes("add") ? "Add" : "Update";
  const usertype = window.localStorage.getItem("user");

  const name = useRef(null);
  const email = useRef(null);
  const phone = useRef(null);
  const address = useRef(null);
  const utype = useRef(null);
  const password = useRef(null);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();

    const userdata = {};
    userdata["name"] = name.current.value;
    userdata["email"] = email.current.value;
    userdata["phone"] = phone.current.value;
    userdata["address"] = address.current.value;
    userdata["type"] = utype.current.value;
    userdata["password"] = password.current.value;

    axios
      .post("http://nxk9083.uta.cloud/api/public/adduser.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status_code == 1) {
          if (usertype == "3") {
            navigate("/superadmin/users/");
          } else if (usertype == "2") {
            navigate("/schooladmin/users/");
          }
        }
      });

    event.target.reset();
  };

  return (
    <div>
      <form className="form-style-9" onSubmit={handleSubmit}>
        <h1>{type}</h1>
        <ul>
          <li>
            <input
              ref={name}
              type="text"
              name="field1"
              className="field-style field-split align-left"
              placeholder={type + " Name"}
              required
            />
            <input
              ref={email}
              type="email"
              name="field2"
              className="field-style field-split align-right"
              placeholder={type + " Email"}
              required
            />
            <input
              ref={password}
              type="password"
              name="field2"
              className="field-style field-split align-right"
              placeholder={type + " Password"}
              required
            />
          </li>
          <li>
            <input
              ref={phone}
              type="text"
              name="field3"
              className="field-style field-split align-left"
              placeholder="Phone"
            />
            <input
              ref={address}
              type="text"
              name="field4"
              className="field-style field-split align-right"
              placeholder="Address"
            />
            <input
              ref={utype}
              type="text"
              name="field1"
              className="field-style field-split align-left"
              placeholder="User tyepe 1.Student 2.SchAdm 3.BusOwn"
              required
            />
          </li>
          {/* <li>
    <input type="text" name="field3" className="field-style field-full align-none" placeholder="Subject" />
    </li> */}
          {/* <li>
    <textarea name="field5" className="field-style" placeholder="Message"></textarea>
    </li> */}
          <li>
            <input type="submit" value={op} />
          </li>
        </ul>
      </form>
    </div>
  );
}

export default AddForm;
