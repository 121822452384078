import React, { useState, useEffect } from "react";
import "./Admin.css";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Dashboard from "../Dashboard/Dashboard";
import axios from "axios";

function AdminUsers() {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const [userDetails, setUserDetails] = useState(true);
  const [productDetails, setProductDetails] = useState([]);
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));

  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "3"
    ) {
      alert(
        "You are not authorized to access this page. Login with super admin credentials"
      );
      navigate("/login");
    }
  });

  useEffect(() => {
    if (flag) {
      axios.get("http://nxk9083.uta.cloud/api/public/users.php").then((result) => {
        const data = result.data;
        setProductDetails(data);
      });
    }
    setFlag(false);
  });
  return (
    <>
      {/* <Header/> */}
      <Dashboard />

      <section className="dashboard">
        <div className="top1">
          <i className="uil uil-bars sidebar-toggle"></i>

          {/* <div className="search-box1">
            <i className="uil uil-search"></i>
            <input type="text" placeholder="Search here..." />
          </div> */}
        </div>

        <div className="dash-content">
          {/* <div className="overview">
          <div className="title">
            <i className="uil uil-tachometer-fast-alt"></i>
            <span className="text">Dashboard</span>
          </div>
        </div> */}
          <div className="cselect">
            {/* <div className="custom-select">
              <select>
                <option value="Student">Student</option>
                <option value="Business Owne">Business Owner</option>
                <option value="School Admin">School Admin</option>
                <option value="Others">Others</option>
              </select>
            </div> */}
          </div>
          <div className="activity">
            <div className="title">
              <i className="uil uil-clock-three"></i>
              <span className="text">Users</span>
            </div>
            <NavLink to={"/superadmin/users/add/2"}>
              <span
                className="data-title"
                style={{
                  float: "right",
                  paddingRight: "50px",
                  paddingBottom: "20px",
                }}
              >
                Add User
              </span>
            </NavLink>
            <div className="activity-data">
              <div className="data email">
                <span className="data-title">ID</span>
                {productDetails.map((product) => {
                  return <span className="data-list">{product["id"]}</span>;
                })}
              </div>
              <div className="data names">
                <span className="data-title">Name</span>
                {productDetails.map((product) => {
                  return (
                    <span className="data-list">{product["full_name"]}</span>
                  );
                })}
              </div>
              <div className="data email">
                <span className="data-title">Email</span>
                {productDetails.map((product) => {
                  return (
                    <span className="data-list">{product["email_id"]}</span>
                  );
                })}
              </div>
              <div className="data type">
                <span className="data-title">User Type</span>
                {productDetails.map((product) => {
                  return (
                    <span className="data-list">{product["user_type"]}</span>
                  );
                })}
              </div>
              <div className="data status">
                <span className="data-title">Action</span>
                {productDetails.map((product) => {
                  return (
                    <span className="data-list">
                      <NavLink to={"/superadmin/users/edit/" + product["id"]}>
                        <span className="data-list">Edit</span>
                      </NavLink>{" "}
                      <NavLink to={"/superadmin/users/delete" + product["id"]}>
                        <span className="data-list">Delete</span>
                      </NavLink>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AdminUsers;
