import "./Dashboard.css";
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const SchoolAdminDashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "2"
    ) {
      alert(
        "You are not authorized to access this page. Login with school admin credentials"
      );
      navigate("/login");
    }
  });

  return (
    <>
      <div className="schooladmindashb">
        <nav>
          <div className="logo-name">
            <span className="logo_name">Mercado Escolar</span>
          </div>
          <h6 className="panelHeading">School Admin Panel</h6>
          <div className="menu-items">
            <ul className="nav-links">
              <li>
                <NavLink to={"/schooladmin/"}>
                  <a href="#">
                    <i className="uil uil-estate"></i>
                    <span className="link-name">Insights</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/schooladmin/content"}>
                  <a href="#">
                    <i className="uil uil-estate"></i>
                    <span className="link-name">Moderate Posts </span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/schooladmin/clubs"}>
                  <a href="#">
                    <i className="uil uil-estate"></i>
                    <span className="link-name">Moderate Clubs </span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/schooladmin/users"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Manage Users</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/schooladmin/chat"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Chat</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Logout</span>
                  </a>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default SchoolAdminDashboard;
