import React from "react";
import "./Admin.css";
import { NavLink } from "react-router-dom";
import Header from "../Header/Header";
import Dashboard from "../Dashboard/Dashboard";

const AdminInsights = () => {
  return (
    <>
      {/* <Header/> */}
      <Dashboard />

      <section className="dashboard">
        <div className="top1">
          <i className="uil uil-bars sidebar-toggle"></i>

          <div className="search-box1">
            <i className="uil uil-search"></i>
            <input type="text" placeholder="Search here..." />
          </div>
        </div>
        <div className="dash-content">
          <div className="overview">
            <div className="title">
              <i className="uil uil-tachometer-fast-alt"></i>
              <span className="text">Dashboard</span>
            </div>

            <div className="boxes">
              <div className="box box1">
                <i className="uil uil-thumbs-up"></i>
                <span className="text">Students</span>
                <span className="number">50,120</span>
              </div>
              <div className="box box2">
                <i className="uil uil-comments"></i>
                <span className="text">Business</span>
                <span className="number">20,120</span>
              </div>
              <div className="box box3">
                <i className="uil uil-share"></i>
                <span className="text">Clubs</span>
                <span className="number">10,120</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminInsights;
