import React, { useRef } from "react";
import Header from "../Header/Header";
import "./EditForm.css";
import { useLocation } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";

function CreateClub() {
  let location = useLocation();
  location = location.pathname;
  const navigate = useNavigate();
  const type = location.indexOf("add") > -1 ? "Add" : "Update";
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));
  const usertype = window.localStorage.getItem("user");

  const name = useRef(null);
  const description = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const userdata = {};
    userdata["name"] = name.current.value;
    userdata["description"] = description.current.value;
    userdata["id"] = userDetails1["id"];

    axios
      .post("http://nxk9083.uta.cloud/api/public/addclub.php", userdata)
      .then(function (response) {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status_code == 1) {
          if (usertype == "1") {
            navigate("/student/clubs");
          } else if (usertype == "2") {
            navigate("/schooladmin/clubs");
          }
        }
      });

    event.target.reset();
  };

  return (
    <>
      <div>
        <form className="form-style-9" onSubmit={handleSubmit}>
          <h1>Club</h1>
          <ul>
            <li>
              <input
                ref={name}
                type="text"
                name="field1"
                className="field-style field-split align-left"
                placeholder={"Title"}
                required
              />
            </li>

            <li>
              <input
                ref={description}
                type="text"
                name="field3"
                className="field-style field-split align-left"
                placeholder="Description"
                required
              />
            </li>

            <li>
              <input type="submit" value={type} />
            </li>
          </ul>
        </form>
      </div>
    </>
  );
}

export default CreateClub;
