import React from "react";
import "./Admin.css";
import { NavLink } from "react-router-dom";
import Header from "../Header/Header";
import Dashboard from "../Dashboard/Dashboard";

const AdminSchools = () => {
  return (
    <>
      {/* <Header/> */}
      <Dashboard />

      <section className="dashboard">
        <div className="top1">
          <i className="uil uil-bars sidebar-toggle"></i>

          <div className="search-box1">
            <i className="uil uil-search"></i>
            <input type="text" placeholder="Search here..." />
          </div>
        </div>

        <div className="dash-content">
          <div className="activity">
            <div className="title">
              <i className="uil uil-clock-three"></i>
              <span className="text">Schools</span>
            </div>
            <NavLink to={"/superadmin/schools/add/1"}>
              <span
                className="data-title"
                style={{
                  float: "right",
                  paddingRight: "50px",
                  paddingBottom: "20px",
                }}
              >
                Add School
              </span>
            </NavLink>
            <div className="activity-data">
              <div className="data names">
                <span className="data-title">Name</span>
                <span className="data-list">UT Dallas</span>
                <span className="data-list">UT Dallas</span>
                <span className="data-list">UT Dallas</span>
                <span className="data-list">UT Dallas</span>
              </div>
              <div className="data names">
                <span className="data-title">Location</span>
                <span className="data-list">Dallas</span>
                <span className="data-list">Dallas</span>
                <span className="data-list">Dallas</span>
                <span className="data-list">Dallas</span>
              </div>
              <div className="data names">
                <span className="data-title">Action</span>
                <span className="data-list">
                  <NavLink to={"/superadmin/schools/edit/1"}>
                    <span className="data-list">Edit</span>
                  </NavLink>{" "}
                  <NavLink to={"/superadmin/schools/delete"}>
                    <span className="data-list">Delete</span>
                  </NavLink>
                </span>
                <span className="data-list">
                  <NavLink to={"/superadmin/schools/edit/1"}>
                    <span className="data-list">Edit</span>
                  </NavLink>{" "}
                  <NavLink to={"/superadmin/schools/delete"}>
                    <span className="data-list">Delete</span>
                  </NavLink>
                </span>
                <span className="data-list">
                  <NavLink to={"/superadmin/schools/edit/1"}>
                    <span className="data-list">Edit</span>
                  </NavLink>{" "}
                  <NavLink to={"/superadmin/schools/delete"}>
                    <span className="data-list">Delete</span>
                  </NavLink>
                </span>
                <span className="data-list">
                  <NavLink to={"/superadmin/schools/edit/1"}>
                    <span className="data-list">Edit</span>
                  </NavLink>{" "}
                  <NavLink to={"/superadmin/schools/delete"}>
                    <span className="data-list">Delete</span>
                  </NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminSchools;
