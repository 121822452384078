import React from "react";
import "./Header.css";
import { NavLink, Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <div className="nav">
        <input type="checkbox" id="nav-check" />
        <div className="nav-header">
          <div className="nav-title">Mercado Escolar</div>
        </div>
        <div className="nav-btn">
          <label for="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <div className="nav-links">
          <NavLink to={"/"}>
            <a href="" style={{ color: "white" }}>
              Home
            </a>
          </NavLink>
          <NavLink to={"/about"}>
            <a href="" style={{ color: "white" }}>
              About
            </a>
          </NavLink>
          <NavLink to={"/services"}>
            <a href="" style={{ color: "white" }}>
              Services
            </a>
          </NavLink>
          <a href="https://blog.axl8622.uta.cloud" style={{ color: "white" }}>
            Blog
          </a>
          <NavLink to={"/contact"}>
            <a href="" style={{ color: "white" }}>
              Contact
            </a>
          </NavLink>
          <NavLink to={"/login"}>
            <a href="" style={{ color: "white" }}>
              Sign In
            </a>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Header;
