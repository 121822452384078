import React, { useState, useEffect } from "react";
import "./Club.css";
import SchoolAdminDashboard from "../Dashboard/SchoolAdminDashboard";
import { NavLink, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import axios from "axios";

function SchoolAdminClub() {
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true);
  const [userDetails, setUserDetails] = useState(true);
  const [productDetails, setProductDetails] = useState([]);
  const [clubdetails, setclubdetails] = useState([]);
  const userDetails1 = JSON.parse(window.localStorage.getItem("userdetails"));

  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "2"
    ) {
      alert(
        "You are not authorized to access this page. Login with school admin credentials"
      );
      navigate("/login");
    }
  });

  useEffect(() => {
    if (flag) {
      axios.get("http://nxk9083.uta.cloud/api/public/clubs.php").then((result) => {
        const data = result.data;
        setProductDetails(data);
        console.log(data);
      });
      axios
        .get("http://nxk9083.uta.cloud/api/public/clubs.php?id=" + userDetails1["id"])
        .then((result) => {
          const data = result.data;
          setclubdetails(data);
          console.log(data);
        });
    }
    setFlag(false);
  });
  return (
    <>
      {/* <Header/> */}
      <SchoolAdminDashboard />
      <div className="card-lis">
        <br />
        <div>
          <center>
            <h1>Clubs</h1>
          </center>
        </div>
        <div>
          <NavLink to={"add/5"}>
            <button style={{ float: "right", margin: "2% 5%" }}>
              <span className="data-title" style={{ float: "right" }}>
                Create Club
              </span>
            </button>
          </NavLink>
        </div>

        <ul className="card-wrapper">
          {productDetails.map((product) => {
            return (
              <li className="card">
                <img src={product["image"]} alt="" />
                <p>{product["name"]}</p>
                <p>{product["description"]}</p>
                <NavLink to={"/schooladmin/content/update/" + product["id"]}>
                  <button
                    style={{ padding: "20px 25px", marginBottom: "10px" }}
                  >
                    Update
                  </button>
                </NavLink>
                <button style={{ padding: "20px 25px", marginBottom: "10px" }}>
                  Delete
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default SchoolAdminClub;
