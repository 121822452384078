import React from "react";
import "./Dashboard.css";
import { NavLink } from "react-router-dom";

const StudentDashboard = () => {
  return (
    <>
      <div className="studentdashb">
        <nav>
          <div className="logo-name">
            <span className="logo_name">Mercado Escolar</span>
          </div>
          <h6 className="panelHeading">Student Panel</h6>

          <div className="menu-items">
            <ul className="nav-links">
              <li>
                <NavLink to={"/student/content"}>
                  <a href="#">
                    <i className="uil uil-estate"></i>
                    <span className="link-name">Content</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/student/clubs"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Clubs</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/student/products"}>
                  <a href="#">
                    <i className="uil uil-chart"></i>
                    <span className="link-name">Products</span>
                  </a>
                </NavLink>
              </li>
              <li>
                {/* <NavLink to={"/student/ads"}>
          <a href="#">
            <i className="uil uil-chart"></i>
            <span className="link-name">Advertisements</span>
          </a>
        </NavLink> */}
              </li>
              <li>
                {/* <NavLink to={"/student/users"}>
                  <a href="#">
                    <i className="uil uil-thumbs-up"></i>
                    <span className="link-name">Students</span>
                  </a>
                </NavLink> */}
              </li>
              <li>
                <NavLink to={"/student/profile"}>
                  <a href="#">
                    <i className="uil uil-thumbs-up"></i>
                    <span className="link-name">My Profile</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/chat"}>
                  <a href="#">
                    <i className="uil uil-thumbs-up"></i>
                    <span className="link-name">Chat</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/cart"}>
                  <a href="#">
                    <i className="uil uil-thumbs-up"></i>
                    <span className="link-name">Cart</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/orders"}>
                  <a href="#">
                    <i className="uil uil-thumbs-up"></i>
                    <span className="link-name">Orders</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Logout</span>
                  </a>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default StudentDashboard;
