import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import { NavLink, useNavigate } from "react-router-dom";

const BusinessDashboard = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !window.localStorage.getItem("user") ||
      window.localStorage.getItem("user") != "4"
    ) {
      alert(
        "You are not authorized to access this page. Login with business credentials"
      );
      navigate("/login");
    }
  });
  return (
    <>
      <div className="buisdashb">
        <nav>
          <div className="logo-name">
            <span className="logo_name">Mercado Escolar</span>
          </div>
          <h6 className="panelHeading">Business Owner Panel</h6>

          <div className="menu-items">
            <ul className="nav-links">
              <li>
                <NavLink to={"/business/dashboard"}>
                  <a href="#">
                    <i className="uil uil-estate"></i>
                    <span className="link-name">Insights</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/business/products"}>
                  <a href="#">
                    <i className="uil uil-estate"></i>
                    <span className="link-name">Manage Products </span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/business/ads"}>
                  <a href="#">
                    <i className="uil uil-estate"></i>
                    <span className="link-name">Moderate Ads </span>
                  </a>
                </NavLink>
              </li>

              <li>
                <NavLink to={"/business/chat"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Chat</span>
                  </a>
                </NavLink>
              </li>
              <li>
                <NavLink to={"/"}>
                  <a href="#">
                    <i className="uil uil-files-landscapes"></i>
                    <span className="link-name">Logout</span>
                  </a>
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default BusinessDashboard;
